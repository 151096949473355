import React from 'react';

import Typography from '@mui/material/Typography';

import Header from "../header/Header";
import Footer from "../footer/Footer";

import './styles.css';

class ToS extends React.Component {

    render() {
        return (
            <div className="tos">
                <Header/>
                <Typography variant={'h5'}>
                    Terms of Use
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    Welcome to the notiflo web and/or mobile applications (collectively, the “App”), owned and operated by banc labs, LLC (“we”, “us” or “notiflo”). These Terms of Use (these “Terms”) set forth the mutual agreement between notiflo and you as to your rights and responsibilities when you use notiflo’s website, notiflo.com (the “Site”), the App and the services provided by notiflo through the App or the Site (collectively, the “Service”).
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>1. Who can use notiflo:</b> You may use the Service only if you agree to these Terms and to the processing of your information as described in our Privacy Policy. When you access or use the Service, you acknowledge that you have read, understood and agree to be bound by these Terms and our Privacy Policy, which is incorporated herein by reference. The Service is intended ONLY for users in the United States, and use of the Service is governed by US law. You may not use the Service if (a) you are barred from receiving services under the laws of the applicable jurisdiction, (b) we previously banned your account for violation of these Terms, or (c) you are under the age of 18.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>2. Description:</b> The service is designed to make tracking of athletes during sport tournaments easy. Users will sign up and will then receive notifications, such as but not limited to text messages, emails, or other types, about the athlete during the tournament. The notifications will only last as long as the tournament is live, and the user may opt out at any point.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>3. Limited license; restrictions:</b> notiflo grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Service for your own personal, non-commercial purposes subject to these Terms. This license is personal to you and may not be assigned or sublicensed to anyone else. All rights not expressly granted by notiflo herein are reserved. You agree that you will not reproduce, redistribute, transfer, sell, create derivative works from, decipher, decompile, reverse engineer, alter, adapt, or disassemble any portion of the Service or any source code therein or attempt to do any of the foregoing. You further agree that you will not remove any copyright, trademark or other proprietary rights notices contained in or displayed on any portion of the Service. In accessing and using the Service, you agree to comply with all applicable laws and legal requirements.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>4. Your account:</b> In order to access certain features of the Service, you will have to create an account by providing a phone number. You are solely responsible for the activity that occurs on your account, including any activity by authorized or unauthorized users. If you become aware of any breach of security or unauthorized use of your account, you must notify notiflo immediately.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>5. Notification Charges:</b> notiflo may send you notifications through various communication channels, such as but not limited to text messages, email, or other types. Text messages may arrive from local or toll-free numbers The user assumes the costs associated with receiving these messages on their devices, notiflo will not be responsible in any way for these charges.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>6. Paid subscriptions:</b> Some users may purchase a paid monthly or annual subscription to the App that may include several additional features or services. Payments for subscriptions are collected via notiflo’s third party payment processors, and you authorize us to receive payments for the Service via such third party payment processors, using the payment information you have supplied. These subscriptions will not automatically renew for the same period of time as the original, the user will have to re-subscribe following expiration. A subscription may be cancelled at any time; however, all fees paid for such subscription are non-refundable. The price at which the subscription is offered may vary due to date of subscription, country, local taxes and regulations, promotions, and notiflo reserves the right to change the price and subscription benefits at its sole discretion. If you have issues with our subscription or would like to request a refund, please email hello@notiflo.com. We reserve the right to offer or refuse refunds at our sole discretion. Subscriptions may be suspended or terminated for overdue payments. notiflo reserves the right to offer or refuse subscriptions for any reason at our sole discretion.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>7. Content terms and restrictions:</b> As used throughout these Terms, “Content” includes documents, photographs, graphics, images, audio, videos, text and other materials of any and every type in all forms and mediums. The Content provided by notiflo and appearing within the Service (the “notiflo Content”), including its “look and feel” (e.g., text, graphics, images, logos and button icons), editorial content, notices, and software (including html-based computer programs), are protected under both United States and other applicable copyright, trademark and other laws. The notiflo Content belongs or is licensed to notiflo, and notiflo and its licensors and software or content suppliers retain all trademark, copyright, and other intellectual property rights in the notiflo Content. You may download or print a copy of notiflo Content for your personal, internal and non-commercial use only. Any distribution, reprint or reproduction of any notiflo Content in whole or in part for any other purpose is expressly prohibited without our prior written consent. You agree not to use, nor permit any third party to use, notiflo Content in a manner that violates any applicable law, regulation or these Terms.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>8. Conditions of use:</b> You agree that you will use the Service for its intended purpose. In accessing or using the Service, you agree that you will not:
                    <ol type="a">
                        <li>
                            use the Service in a fraudulent, disruptive, aggressive, or manipulative manner, for money laundering, or any other inappropriate purpose
                        </li>
                        <li>
                            use any robot, spider, scraper, deep link or other similar automated data gathering or extraction tools, program, algorithm or methodology to access, acquire, copy or monitor the Service or any portion of the Service, without notiflo’s express written consent, which may be withheld in notiflo’s sole discretion;
                        </li>
                        <li>
                            use or attempt to use any engine, software, tool, agent, or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search the Service, other than the search engines and search agents available through the Service and other than generally available third-party web browsers (such as Google Chrome);
                        </li>
                        <li>
                            post or transmit any file which contains viruses, worms, Trojan horses or any other contaminating or destructive features, or that otherwise interfere with the proper working of the Service;
                        </li>
                        <li>
                            spam, hack into or deface the Service;
                        </li>
                        <li>
                            circumvent, disable or otherwise interfere with security features of the Service;
                        </li>
                        <li>
                            defame, harass, stalk, threaten or otherwise violate the rights of others, including, without limitation, others' moral, privacy or publicity rights;
                        </li>
                        <li>
                            impersonate any person or entity or use any fraudulent, misleading or inaccurate email address, phone number, or other contact information;
                        </li>
                        <li>
                            violate any applicable laws or regulations
                        </li>
                        <li>
                            submit, upload, or display Content that: (A) is unlawful, fraudulent, threatening, abusive, libelous, defamatory, obscene or otherwise objectionable; (B) contains sexually explicit Content, pornography, or nudity; (C) contains hateful, malicious, or discriminatory Content, incites hatred towards any individual or group, or incites or advocates terrorism or violence; (D) infringes our or any third party's intellectual property or other rights; (E) contains any confidential, proprietary or trade secret information of any third party; or (F) includes any advertisements, solicitations, investment opportunities or other unsolicited commercial or chain communications or promotes the sale of goods or services;
                        </li>
                        <li>
                            harm or exploit minors; or
                        </li>
                        <li>
                            use the Service for any commercial, advertising or solicitation uses or collect any personally identifiable information about others.
                        </li>
                    </ol>
                    notiflo reserves the right, but has no obligation, to investigate your use of the Service for compliance with these Terms, and in addition to any other rights and remedies we may have, notiflo may immediately terminate your access to the Service due to a violation of these Terms or in order to comply with any law, regulation, legal process or government request.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>9. What you represent:</b> You agree to represent yourself honestly, that all of the information you provide is true, correct, and current, to the best of your knowledge, and that you have the necessary rights, power, authority, and age to agree to these Terms and to perform the acts required of you by these Terms.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>10. Termination and Refusal of Service:</b> Your access to the Service may be terminated at any time, for any reason, at notiflo’s sole discretion. This includes the right to ban any person from the Service who uses the Service in a way that violates any provision of these Terms. If notiflo deletes your account, you may not re-register for the Service. notiflo may block your email address, phone number, and Internet protocol address to prevent further registration. Upon termination, all licenses granted by notiflo will terminate automatically. In the event of an account deletion for any reason, Content that you submitted may no longer be available, and notiflo shall not be responsible for the loss of such Content.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>11. Disclaimers:</b>
                    <ol type="a">
                        <li>
                            notiflo does not represent that the Service is error-free, complete, or can be relied upon. THE SERVICE IS PROVIDED 'AS IS' AND 'AS AVAILABLE' WITH NO WARRANTY OF ANY KIND AND YOU ARE USING THE SERVICE AT YOUR OWN RISK.
                        </li>
                        <li>
                            TO THE FULLEST EXTENT PERMITTED BY LAW, NOTIFLO EXPRESSLY DISCLAIMS ANY WARRANTY, IMPLIED OR OTHERWISE, REGARDING THE SERVICE AND YOUR USE OF THE SERVICE, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, AND ANY OTHER WARRANTY THAT MIGHT ARISE UNDER ANY LAW.
                        </li>
                        <li>
                            Without limiting the foregoing, notiflo expressly disclaims, and assumes no liability or responsibility for, any: (i) errors, omissions, or inaccuracies of Content; (ii) personal injury or tangible or intangible property damage or loss of any nature whatsoever resulting from your access to or use of the Service; (iii)  unauthorized access to or use of the Service and/or any Content; (iv) interruption or cessation of transmission to or from the Service; (v) bugs, viruses, trojan horses or the like which may be transmitted to, by or through the Service; (vi) Content submitted by any user; or (vii) loss or damage of any kind incurred as a result of the use of the Service or any Content posted, transmitted, or otherwise made available through the Service, including any loss of your Content.
                        </li>
                    </ol>
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>12. No Liability:</b> TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES WILL NOTIFLO BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES (INCLUDING EMOTIONAL DISTRESS) ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE, WHETHER OR NOT YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNDER NO CIRCUMSTANCES SHALL NOTIFLO BE LIABLE TO YOU FOR ANY AMOUNT FOR SERVICES RENDERED PURSUANT TO THESE TERMS.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>13. Indemnification:</b> To the fullest extent permitted by applicable law, you agree to defend, indemnify and hold harmless notiflo, its employees, directors, officers, agents, and representatives, from any third party claim arising from or in any way related to or arising from: (i) your access to or use of the Service, (ii) your violation of any of these Terms, (iii) your violation of any third party right, including without limitation any copyright, property, or privacy or publicity right, or (d) any claim that your Content caused damage, harm or loss  to a third party; including liability or expense arising from all claims, losses, damages (actual and consequential), suits, judgments, litigation costs, and attorney fees, of every kind and nature.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>14. Modifications to these Terms:</b> You agree that notiflo may modify these Terms at any time, effective immediately, by posting a modified version of these Terms on the Service. You agree that even if you have not personally used the Service with the new Terms, that they may have force immediately. If you do not consent to these Terms, as modified, you should not access or use the App in any way. By continuing to access or use the Service after the modifications become effective, you agree to be bound by the modified Terms.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>15. Severability:</b> If any part of these Terms is held to be invalid or unenforceable, the provision shall be deemed to be superseded by a valid enforceable provision in its place that closely matches the intent of the original agreement, and all other parts of these Terms shall be enforced.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>16. Waiver:</b> You agree that the failure of notiflo to exercise or enforce any part of these Terms does not constitute a waiver of the rights or provisions of these Terms.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>17. Governing Law; Jurisdiction:</b> You agree that this entire agreement (including the Privacy Policy) shall be interpreted according to the laws of the State of New Hampshire, without regard to principles of conflicts of law. Any action arising out of or relating to these Terms or your use of the Service must be commenced in the state or federal courts located in New Hampshire, United States of America, and you consent to the jurisdiction of such courts.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>18. Entire agreement:</b> These Terms, including the Privacy Policy and any other legal notices published by us on the Service, comprise the entire agreement between you and notiflo with respect to the subject matter set forth herein.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>19. Third Party Apps or Websites:</b>
                    <ol type="a">
                        <li>
                            The Service may contain links to third-party websites or applications. notiflo has no control over, and assumes no responsibility for, the content, practices or policies (including privacy policies) of any such third parties. By using the Service, you expressly release notiflo from any and all liability arising from your use of any third-party website or application.
                        </li>
                    </ol>
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    Last Revised: January 2, 2023
                </Typography>

                <div className="tos-break"/>
                <div className="tos-break"/>
                <Footer/>
            </div>
        );
    }

}

export default ToS;
