import React from 'react';

import Typography from '@mui/material/Typography';

import Header from "../header/Header";
import Footer from "../footer/Footer";

import './styles.css';

class PrivacyPolicy extends React.Component {

    render() {
        return (
            <div className="tos">
                <Header/>
                <Typography variant={'h5'}>
                    Privacy Policy
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>1. General</b>
                    <div className="tos-break"/>

                    Welcome, and thank you for your interest in notiflo (“notiflo”, “us” or “we”) and our services. This notiflo Privacy Policy (“Privacy Policy”) explains how notiflo collects, uses, and shares information about you. This Privacy Policy applies to information we collect when you use notiflo.com (the “Site”), notiflo’s web applications, and/or its mobile applications, (collectively, the “Apps,” together with the Site, the “Service”).
                    <div className="tos-break"/>

                    Please read this Privacy Policy carefully. By using the Site, Apps or Service, you acknowledge that you have read, understood, and agree to be bound by the terms of this Privacy Policy and consent to notiflo’s collection and use of personally identifiable information (“Personal Information”) and other information as set forth herein. <b>If you do not consent to the terms of this Privacy Policy, you should not access or use the Site in any way and should immediately exit the Site.</b>
                    <div className="tos-break"/>

                    This Privacy Policy is incorporated into and is subject to the notiflo Terms of Use.
                    <b> notiflo is intended ONLY for users in the United States, and its use is governed by US law.</b>

                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>2. The information that we collect</b>
                    <div className="tos-break"/>
                    <ol>
                        <li>
                            <b>Personal Information: </b>
                            We collect certain Personal Information that you provide when you register to use the Service, including personal and business phone numbers.
                        </li>
                        <div className="tos-break"/>
                        <li>
                            <b>"Automatically Collected " Information: </b>
                            By choosing to use the Service or opening one of our e-mails, you agree to provide notiflo with certain information that is automatically collected (“Use Data”) including, without limitation, your IP address or other device address or ID, your web browser and/or device type, your geographic location, and information about your usage of the site, including the web pages or sites that you visit just before or just after the Service, the pages you view on the Service, your actions on the Service, and the dates and times that you visit, access, or use the Service,.

                            <div className="tos-break"/>
                            notiflo uses third-party software tools including Google Analytics, “cookies” and similar technologies, and “web beacons” to gather Use Data. Google Analytics is a service that tracks website traffic. Cookies are small pieces of data that are stored on your device.
                            Please review your web browser “Help” file to learn how to modify your cookie settings. Please note that if you delete, or choose not to accept, cookies from the Service, you may not be able to utilize the features of the Service to their fullest potential.
                        </li>
                    </ol>
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>3. How we use your information</b>

                    <div className="tos-break"/>
                    Your information is an integral part of our operations, and we use it in a variety of ways in providing the Service and operating our business. For example:

                    <ol>
                        <li>
                            We use Personal Information, Content and Use Data, to operate, maintain, enhance and provide all of the features of the Service, to notify you about important updates to the Service, to answer your inquiries and requests about the Service, and to screen for and prevent fraud and abuse.
                        </li>
                        <li>
                            We use Personal Information, Content and Use Data to understand and analyze the usages trends and preferences of our users, to improve the Service, and to create new features and functionality.
                        </li>
                        <li>
                            We may use your phone number or other Personal Information to contact you for administrative purposes such as customer service, to address intellectual property infringement, right of privacy violations or defamation issues related to your Personal Information posted on the Service. We may also use your phone number to send you Service alerts and notifications. You may opt-out of receiving Service alert and notification e-mails by updating your account settings through the Service, or by contacting us directly at hello@notiflo.com
                        </li>
                        <li>
                            We may also provide you the opportunity to provide your name and email address to subscribe to certain newsletters, updates or offers related to the Service and the activities of third parties we work with. We will not send you an email newsletter, update or offer unless you request it.  By providing notiflo with your email address, you authorize notiflo to send you regular emails unless and until you request to unsubscribe from such emails. Each email newsletter, update or offer that we send to you will provide an opportunity to opt out of future email correspondence.
                        </li>
                        <li>
                            We may use Use Data to: (a) personalize our services, such as remembering your information so that you will not have to re-enter it during your visit or the next time you visit the Service; (b) provide customized advertisements, content, and information; (c) monitor and analyze the effectiveness of the Service and third party marketing activities; and (d) monitor aggregate site usage metrics such as total number of visitors and pages viewed.
                        </li>
                        <li>
                            6.	We may collect, use, and share Use Data in an aggregated, de-identified, or anonymized manner (that does not identify you personally) for any purpose permitted under applicable law. This includes creating or using aggregated, de-identified, or anonymized data based on the collected information to develop new services and to facilitate research.
                        </li>
                    </ol>
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>4. How we share your information</b>
                    <div className="tos-break"/>

                    We do not sell our customers' Personal Information. We share your information as described below
                    <div className="tos-break"/>

                    <ol>
                        <li>
                            <b>Service Providers: </b>
                            We engage certain trusted third parties to perform functions and provide services to us, including, without limitation, notification services. We may share your Personal Information with these third parties, but only to the extent necessary to perform these functions and provide such services.

                            <div className="tos-break"/>
                            When you make a payment for the Service, we will direct you to, a third-party payment processor, to provide a credit card number and other personal data needed to complete the transaction. Our payment processor will be certified to meet PCI DSS, the payment card industry standards for security, as well as applicable laws. You may receive email correspondence from us and or the payment processor related to any online transaction.
                        </li>
                        <div className="tos-break"/>
                        <li>
                            <b>Other Third Parties: </b>
                            We also may share, disclose, or transfer Personal Information, Content or Use Data with third parties as follows: (a) to government agencies, advisors and other third parties, where necessary and appropriate, in order to comply with applicable laws, the service of legal process, or if we reasonably believe that such action is necessary to (i) comply with the law requiring such disclosure, (ii) protect notiflo’s rights or property, (iii) prevent a crime or protect national security or (iv) protect the personal safety of the users or the public; (b) if we believe your actions violate our Terms of Use or other policies; or (c) in connection with a financing or a sale, acquisition, merger, other change of control, reorganization or licensing of our business or our business assets. We may also share with others aggregated or anonymized information that does not directly identify you.
                        </li>
                    </ol>
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>5. Your Choices</b>
                    <div className="tos-break"/>

                    You may, of course, decline to share certain Personal Information and Content with us, in which case we may not be able to provide to you some of the features and functionality of the Service. If you would like us to delete your phone number or other personal information, please send us a request at hello@notiflo.com.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>6. Third Party Services</b>
                    <ol>
                        <li>
                            <b>Third Party Web Beacons and Third Party Buttons: </b>
                            We may also implement third party content or advertising on the Service that may use clear gifs or other forms of web beacons, which allow the third party content provider to read and write cookies to your browser in connection with your viewing of the third party content on the Service. Additionally, we may implement third party buttons (such as Facebook “like” or “share” buttons) that may allow such third party to collect information about you through such third party’s browser cookies, even when you do not interact with the button. Information collected through web beacons and buttons is collected directly by the third party, and notiflo does not participate in that data transmission. Information collected by a third party in this manner is subject to that third party’s own data collection, use, and disclosure policies.
                        </li>
                        <div className="tos-break"/>
                        <li>
                            <b>Third Party Websites and Services: </b>
                            The Service may contain features or links to websites and services provided by third parties. Any personally identifiable information you provide on third party sites or services is provided directly to that third party and is subject to that third party’s policies, if any, governing privacy and security, even if accessed through the Service. We are not responsible for the content or privacy and security practices and policies of third party sites or services to which links or access are provided through the Service. We encourage you to learn about third parties’ privacy and security policies before providing them with personally identifiable information.
                        </li>
                    </ol>

                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>7. Our Commitment to Children’s Privacy</b>
                    <div className="tos-break"/>

                    Protecting the privacy of children is especially important. For that reason, we do not allow children under 18 years of age to use the Service or knowingly collect or maintain personally identifiable information from persons under 18 years of age, and no part of the Service is directed to persons under 18 years of age. <b>If you are under 18 years of age, then please do not use or access the Service at any time or in any manner.</b>
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>8. Our Commitment to Data Security</b>
                    <div className="tos-break"/>

                    notiflo uses reasonable care to protect the privacy and security of Personal Information and Content, and to ensure that our systems are secure and meet industry standards. Unfortunately, no Internet transmission is ever completely secure, and we cannot, ensure or warrant the security of any information you transmit to us or store on the Service, and you do so at your own risk.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>9. International Visitors</b>
                    <div className="tos-break"/>

                    The Service is hosted in the United States and is intended solely for users located within the United States. If you choose to use the Service from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring your personal information outside of those regions to the United States for storage and processing, and by providing your Personal Information on the Service you consent to that transfer, storage, and processing.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>10. Changes and Updates to this Privacy Policy</b>
                    <div className="tos-break"/>

                    Please revisit this page periodically to stay aware of any changes to this Privacy Policy. We may amend, update or modify the terms of this Privacy Policy at any time, by posting an amended Privacy Policy on the Site. Changes to this Privacy Policy are effective when they are posted on this page. If you are a registered user of the Service, you will be required to accept such modified terms in order to continue to use the Service. <b>If you do not consent to the terms of this Privacy Policy, as amended, you should not access or use the Service in any way and should immediately exit the Service. By continuing to access or use the Service after those changes become effective, you agree to be bound by the revised Privacy Policy.</b>
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>11. Do Not Track Disclosures</b>
                    <div className="tos-break"/>

                    Please note that we do not alter our Service’s data collection and use practices when we see a Do Not Track (DNT) signal from your browser. Third parties such as advertising networks, analytics providers, and widget providers may collect information about your online activities over time and across different websites when you access or use our Service.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>12. UNAUTHORIZED USE</b>
                    <div className="tos-break"/>

                    Unauthorized attempts to use the Service in a way that violates our Terms of Use, to utilize the Service for other than its intended purposes, or to defeat or circumvent the Service’s security features are strictly prohibited.
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>Our Contact Information</b>
                    <div className="tos-break"/>

                    Please contact us with any questions or comments about this Privacy Policy by e-mail at hello@notiflo.com
                </Typography>

                <div className="tos-break"/>
                <div className="tos-break"/>
                <Typography variant={'p'}>
                    Last Revised: January 2, 2023
                </Typography>

                <div className="tos-break"/>
                <div className="tos-break"/>
                <Footer/>
            </div>
        );
    }

}

export default PrivacyPolicy;
