import React from 'react';

import './styles.css';

import AppBar from '@mui/material/AppBar';

class Header extends React.Component {

    render() {
        const {children} = this.props;

        if (!this.props) {
            return null;
        }

        return (
            <div className="app-bar-container">
                <AppBar position="fixed" color="primary">
                    <div className="app-bar-subcontainer">
                        <a href="/">
                            <img
                                className="header-img"
                                height={40}
                                alt=""
                                src={process.env.PUBLIC_URL + '/notiflo_white.png'}
                            />
                        </a>
                    </div>
                </AppBar>
                {children}
            </div>
        );
    }

}

export default Header;
