import React from 'react';

import './styles.css';

import Header from "../header/Header";
import Footer from "../footer/Footer";

import NotifloFetch from "../../fetch/notifloFetch";

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';

class TournamentSelect extends React.Component {
    render() {
        return (
            <Autocomplete
                className="center-component"
                sx={{ width: "80%" }}
                options={this.props.tournaments}
                autoHighlight
                disabled={this.props.tournaments.length === 0}
                getOptionLabel={(option) => option.name}
                onChange={this.onChange}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        {option.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Choose a tournament"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        );
    }

    onChange = (event, value) => {
        this.props.onTournamentChange(value);
    }
}

class PhoneNumberInput extends React.Component {
    static prefix = "+1 ";

    state = {
        displayPhoneNumber: "",
        phoneNumber: "",
        phoneNumberIsValid: false
    }

    render() {
        return (
            <Box component="form" noValidate autoComplete="off">
                <FormControl sx={{ width: "30ch" }}>
                    <OutlinedInput
                        onChange={this.onChange}
                        placeholder="Please enter your phone number"
                        value={this.state.displayPhoneNumber}
                    />
                </FormControl>
            </Box>
        );
    }

    onChange = (event) => {
        let parsed = this.parseDisplayNumber(event.target.value);

        if (isNaN(parsed)) {
            return;
        }

        if (event.target.value.length < this.state.displayPhoneNumber.length && this.state.displayPhoneNumber.endsWith(' ')) {
            parsed = parsed.substring(0, parsed.length-1);
        }

        this.setState({phoneNumber: parsed, displayPhoneNumber: this.formatDisplayNumber(parsed)})
        this.props.onPhoneNumberChanged("+1"+parsed, parsed.length === 10)
    }

    parseDisplayNumber = (phone_number) => {
        return phone_number
            .replaceAll(' ', '')
            .replace('+1', '')
            .replace('(', '')
            .replace(')', '')
            .replace('-', '');
    }

    formatDisplayNumber = (phone_number) => {

        if (phone_number.length < 3) {
            return PhoneNumberInput.prefix + phone_number;
        } else if (phone_number.length < 6) {
            return PhoneNumberInput.prefix + "(" + phone_number.substring(0,3) + ") " + phone_number.substring(3);
        } else {
            return PhoneNumberInput.prefix + "(" + phone_number.substring(0,3) + ") " + phone_number.substring(3,6) + " - " + phone_number.substring(6);
        }
    }
}

class AthleteSearch extends React.Component {
    state = {
        athletes: [],
        fetching: false,
        fetchErrorCode: null,
        searchTerm: "",
        suggestionTimer: null
    }

    render() {

        return (
            <Autocomplete
                className="center-component"
                sx={{ width: "80%" }}
                disabled={this.props.disabled}
                options={this.state.athletes}
                multiple={true}
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={this.onChange}
                onInputChange={this.onInputChange}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        {option.name} - {option.team.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search for an athlete"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        )
    }

    onInputChange = (event, value) => {
        if (event.target.value === 0) {
            return;
        }

        if (this.state.suggestionTimer) { clearTimeout(this.state.suggestionTimer); }
        this.setState({
            searchTerm: event.target.value,
            suggestionTimer: setTimeout(this.search, 300)
        });
    }

    search = () => {
        this.setState({fetching: true}, () => {
            NotifloFetch.athleteSearch(this.props.tournament_id, this.state.searchTerm).then(response => {
                this.setState({athletes: response, fetching: false});
            }).catch(error => {
                this.setState({
                    fetchErrorCode: error.status,
                    fetching: false
                });
            });
        })
    }

    onChange = (event, value) => {
        this.props.onAthleteChange(value);
    }
}

class Tournaments extends React.Component {

    state = {
        fetching: false,
        fetchErrorCode: null,
        isPhoneNumberValid: null,
        phoneNumber: null,
        tournaments: [],
        selectedAthletes: [],
        selectedTournament: null,
        errorMessage: null,
        successMessage: null
    }

    render() {
        return (
            <div>
                <Header/>
                <Stack spacing={2}>
                    <div className="text-wrapper">
                        <Stack spacing={2}>
                        <Typography variant="h5" component="h5">
                            Smart Tournament Tracking
                        </Typography>
                        <Typography variant="p" component="p">
                            notiFlo allows you to track your wrestler during Flo Arena wrestling tournaments.
                            You sign up per tournament, and will then receive text notifications about mat assignments
                            for your wrestler.

                            To sign up, follow these steps:
                        </Typography>

                        <Typography variant="p" component="p" className="list-item">
                            1. Choose a tournament below.
                        </Typography>
                        <Typography variant="p" component="p" className="list-item">
                            2. Search for a wrestler, you can select multiple wrestlers at once.
                        </Typography>
                        <Typography variant="p" component="p" className="list-item">
                            3. Enter your phone number and click Submit.
                        </Typography>

                        <Typography variant="p" component="p" className="faq-text">
                            For more information, visit our <a className="faq-text" href="/faq">Frequently Asked Questions.</a>
                        </Typography>

                        </Stack>
                    </div>
                    <div className="center-component">
                        {this.state.fetching && <LinearProgress className="fetch-progress"/>}
                        {!this.state.fetching && <div className="fetch-progress-placeholder" />}
                        <TournamentSelect
                            onTournamentChange={this.onTournamentChange}
                            tournaments={this.state.tournaments}
                        />
                    </div>
                    <div className="center-component">
                        <AthleteSearch
                            disabled={this.state.selectedTournament === null}
                            onAthleteChange={this.onAthleteChange}
                            tournament_id={this.state.selectedTournament?.guid}
                        />
                    </div>
                    <div className="center-component">
                        <PhoneNumberInput
                            onPhoneNumberChanged={this.onPhoneNumberChanged}
                        />
                    </div>
                    <div>
                        <Button
                            variant="outlined"
                            disabled={!this.state.isPhoneNumberValid || this.state.selectedAthletes.length === 0}
                            onClick={this.submitNotificationRequest}
                        >
                            Submit
                        </Button>
                    </div>
                </Stack>
                <Snackbar open={this.state.successMessage !== null} autoHideDuration={8000} onClose={this.onCloseSuccessAlert}>
                    <Alert onClose={this.onCloseSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        {this.state.successMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.errorMessage !== null} onClose={this.onCloseErrorAlert}>
                    <Alert onClose={this.onCloseErrorAlert} severity="error" sx={{ width: '100%' }}>
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>
                <Footer/>
            </div>
        );
    }

    componentDidMount() {
        this.setState({fetching: true}, () => {
            NotifloFetch.getTournaments().then(response => {
                this.setState({tournaments: response, fetching: false});
            }).catch(error => {
                this.setState({
                    errorMessage: "Unable to load tournaments, please try again soon.",
                    fetching: false
                });
            });
        })
    }

    onTournamentChange = (tournament) => {
        this.setState({selectedTournament: tournament})
    }

    onAthleteChange = (athletes) => {
        this.setState({selectedAthletes: athletes})
    }

    onPhoneNumberChanged = (phoneNumber, isPhoneNumberValid) => {
        this.setState({phoneNumber: phoneNumber, isPhoneNumberValid: isPhoneNumberValid})
    }

    submitNotificationRequest = () => {
        NotifloFetch.postNotificationRequest(
            this.state.selectedTournament.guid,
            this.state.selectedAthletes,
            this.state.phoneNumber
        ).then(response => {
            this.setState({
                successMessage: "Successfully saved. You will now receive text notifications about your wrestler.",
            });
        }).catch(error => {
            if (error.status === 403) {
                this.setState({
                    errorMessage: "You have unsubscribed from texts. Please reply UNSTOP to (603) 338-6030",
                });
            } else {
                this.setState({
                    errorMessage: "Unable to save your notification. Please try again soon.",
                });
            }
        });
    }

    onCloseSuccessAlert = () => {
        this.setState({successMessage: null})
    }

    onCloseErrorAlert = () => {
        this.setState({errorMessage: null})
    }
}

export default Tournaments;
