import AbstractFetch from './abstractFetch';
import AppConfig from '../appConfig';

class NotifloFetch {

    static getTournaments() {

        const url = AppConfig.apiUrl + "/tournament";
        return AbstractFetch.fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        })
    }

    static athleteSearch(tournment_id, search_term) {

        const url = AppConfig.apiUrl + "/athlete/search";
        return AbstractFetch.fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'tournament_id': tournment_id,
                'search_term': search_term
            })
        }).then((response) => {
            return response.json()
        })
    }

    static postNotificationRequest(tournment_id, athletes, phone_number) {

        const url = AppConfig.apiUrl + "/notification/request";
        return AbstractFetch.fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'athletes': athletes,
                'phone_number': phone_number,
                'tournament_id': tournment_id
            })
        }).then((response) => {
            return response.json()
        })
    }

}

export default NotifloFetch;