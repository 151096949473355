import React from 'react';

import './styles.css';

import Typography from '@mui/material/Typography';

class Header extends React.Component {

    render() {
        return (
            <div className="footer">
                <Typography variant="p" component="p" className="footer-text">
                    <a href="/tos">Terms of Use</a> | <a href="/privacy">Privacy Policy</a> | hello@notiflo.com
                </Typography>
            </div>
        );
    }

}

export default Header;
