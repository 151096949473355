
class FetchException {
    constructor(response) {
        this.status = response.status
        this.response = response;
    }
}

class AbstractFetch {
    static fetch = async (url, options, retry) => {
        return fetch(url, options).then(AbstractFetch.handleErrors);
    };

    static handleErrors(response) {

        if (response.ok) {
            return response;
        }

        throw new FetchException(response);
    }
}

export default AbstractFetch;