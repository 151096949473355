import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Tournaments from "./components/tournaments/Tournaments";
import ToS from "./components/terms_of_service/ToS";
import PrivacyPolicy from "./components/privacy_policy/PrivacyPolicy";
import FAQ from "./components/faq/FAQ";

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<Tournaments />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/tos" element={<ToS />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
