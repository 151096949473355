import React from 'react';

import Typography from '@mui/material/Typography';

import Header from "../header/Header";
import Footer from "../footer/Footer";

import './styles.css';

class FAQ extends React.Component {

    render() {
        return (
            <div className="tos">
                <Header/>
                <Typography variant={'h5'}>
                    Frequently Asked Questions
                </Typography>

                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>How does notiflo work?</b>
                    <div className="tos-break"/>

                    You sign up by first selecting a tournament your athlete is attending. Then you search for your
                    wrestler, you can search and select multiple at a time. Finally, enter your phone number and click
                    Submit.

                    <div className="tos-break"/>

                    You will now receive mat assignments and results for your athlete during the tournament. Once the
                    tournament is over, you will no longer receive notificaions unless you sign up for a future
                    tournament. You can opt out of notifications during a tournament by replying BLOCK to the
                    notiflo number.

                </Typography>

                <div className="tos-break"/>
                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>Why did you build notiflo?</b>
                    <div className="tos-break"/>

                    Same as you, we are parents who attend lots of wrestling tournaments. It is often difficult to track
                    wrestlers throughout the day, especially when you have multiple wrestlers to track! We wanted to
                    break away from staring at the bout board and spend more time watching our kids wrestle.

                </Typography>

                <div className="tos-break"/>
                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>Will you spam me or sell my data?</b>
                    <div className="tos-break"/>

                    We will never spam you with unwanted messages, nor will we sell or give away any of your data.
                    You can read more in our <a className="footer-link" href="/privacy">Privay Policy</a>
                    <div className="tos-break"/>
                    Note that we do not ask for your email or have you create an account with a username/password to use
                    notiflo. We want to make it as easy as possible without needing to give us more information than
                    you have to.

                </Typography>

                <div className="tos-break"/>
                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>Is notiflo free?</b>
                    <div className="tos-break"/>

                    For now, yes, it is free. We may charge a small fee in the future to run the service as it does
                    cost money to operate.

                </Typography>

                <div className="tos-break"/>
                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>When will I receive text messages?</b>
                    <div className="tos-break"/>

                    notiflo will monitor the bout board for you and send you notifications at several key points,
                    mainly when the user is "in the hole" (two bouts away from wrestling on a particular mat), "on deck"
                    (one bout away from wrestling), and "up" (meaning your bout is now ready).
                    <div className="tos-break"/>
                    You will also receive a final text message with the results of the bout.
                    <div className="tos-break"/>
                    Note that sometimes these events can happen quickly in a tournament as bouts can get moved around,
                    wrestlers may forfeit, or any other number of circumstances. This could mean you quickly get two or
                    more text messages. We do not limit the rate at which we send them to you so that you have the most
                    accurate information possible.

                </Typography>

                <div className="tos-break"/>
                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>Can I sign up for tournaments in the future?</b>
                    <div className="tos-break"/>

                    Yes, it doesn't matter how far away the tournament is, as long as the athlete is registered for it,
                    you can sign up with notiflo.

                </Typography>

                <div className="tos-break"/>
                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>Will my mobile carrier charge me for using notiflo?</b>
                    <div className="tos-break"/>

                    We will send you text messages from either a local or toll free phone number. Depending on your
                    mobile plan with your carrier (Verizon, AT&T, etc.) this could result in charges to you. You
                    should check with your carrier plan and make sure regular text messages will not be charged to
                    your before you sign up.

                </Typography>

                <div className="tos-break"/>
                <div className="tos-break"/>
                <Typography variant={'p'}>
                    <b>What if I have feedback?</b>
                    <div className="tos-break"/>

                    We would love to hear from you! You can always email us at hello@notiflo.com, or you can directly
                    text us back at the number we use to notify you.

                </Typography>

                <div className="faq-break"/>
                <div className="faq-break"/>
                <Footer/>
            </div>
        );
    }

}

export default FAQ;
