import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#22577A',
            light: '#22577A',
            dark: '#22577A'
        },
        secondary: {
            main: '#38A3A5',
            light: '#38A3A5',
            dark: '#38A3A5'
        }
    },
    typography: {
        // In Chinese and Japanese the characters are usually larger,
        // so a smaller fontsize may be appropriate.
        fontSize: 16,
    },
});

export default theme;