
var AppConfig = {
    /**
     * Environment
     */
    env: process.env.REACT_APP_ENV,
    /**
     * The URL for all calls
     */
    apiUrl:  process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "prod" ? 'https://api.notiflo.com' :
        process.env.REACT_APP_ENV ? `https://${process.env.REACT_APP_ENV}-api.notiflo.com` : 'http://localhost:5000',
}

export default AppConfig;